<template>
  <div class="nexo-member-table-container">
    <CCard class="nexo-member-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{item, index}">
          <td>{{ index + 1 + (activePage - 1) * limit }}</td>
        </template>
        <template #status="{item}">
          <td style="text-align: center">
            <StakingBadge type="nexo-member-status" :text="item.status" />
          </td>
        </template>
        <template #createdAt="{item}">
          <td style="text-align: center">
            {{ item.createdAt | moment(dateFormat)}}
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import StakingBadge from '@/components/StakingBadge';
import { Constants, env } from '@/constants';
export default {
  name: 'NexoMemberListTable',
  components: {
    StakingBadge,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        '#',
        {
          key: 'email',
          label: this.$t('NEXO_MEMBER_LIST_TABLE_COMPONENT_EMAIL_COL'),
          _style: 'min-width: 180px;text-align: left;',
        },
        {
          key: 'first_name',
          label: this.$t('NEXO_MEMBER_LIST_TABLE_COMPONENT_FIRST_NAME_COL'),
          _style: 'min-width: 120px;text-align: left;',
        },
        {
          key: 'last_name',
          label: this.$t('NEXO_MEMBER_LIST_TABLE_COMPONENT_LAST_NAME_COL'),
          _style: 'min-width: 120px;text-align: left;',
        },
        {
          key: 'nexo_id',
          label: this.$t('NEXO_MEMBER_LIST_TABLE_COMPONENT_NEXO_ID_COL'),
          _style: 'min-width: 120px;text-align: left;',
        },
        {
          key: 'status',
          label: this.$t('NEXO_MEMBER_LIST_TABLE_COMPONENT_STATUS_COL'),
          _style: 'min-width: 120px;text-align: center;',
        },
        {
          key: 'createdAt',
          label: this.$t('NEXO_MEMBER_LIST_TABLE_COMPONENT_DATE_COL'),
          _style: 'min-width: 160px; text-align: center',
        }
      ]
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
}
</script>
