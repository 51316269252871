<template>
  <div class="search-nexo-member-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_NEXO_MEMBER_HEADER_COMPONENT_FIELD_NAME_VALIDATE_EMAIL"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_NEXO_MEMBER_HEADER_COMPONENT_LABEL_EMAIL')"
              type="text"
              v-model="searchData.email"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_NEXO_MEMBER_HEADER_COMPONENT_FIELD_NAME_VALIDATE_LAST_NAME"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_NEXO_MEMBER_HEADER_COMPONENT_LABEL_LAST_NAME')"
              type="text"
              v-model="searchData.last_name"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_NEXO_MEMBER_HEADER_COMPONENT_FIELD_NAME_VALIDATE_FIRST_NAME"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_NEXO_MEMBER_HEADER_COMPONENT_LABEL_FIRST_NAME')"
              type="text"
              v-model="searchData.first_name"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>
      </CRow>
    </ValidationObserver>

    <CRow class="mt-2 form-actions">
      <CCol md="3" sm="12">
        <CButton
          class="btn btn-download-csv"
          v-if="isAllowedDownloadCSV"
          @click="downloadCSV()"
        >{{$t('SEARCH_NEXO_MEMBER_HEADER_COMPONENT_BUTTON_DOWNLOAD_CSV')}}</CButton>
      </CCol>
      <div class="col-md-3 btn-disabled"></div>
      <CCol md="3" sm="12">
        <CButton
          class="btn btn-reset"
          @click="resetForm()"
        >{{$t("SEARCH_NEXO_MEMBER_HEADER_COMPONENT_RESET_BUTTON")}}</CButton>
      </CCol>
      <CCol md="3" sm="12">
        <CButton
          class="btn btn-search"
          @click="search()"
        >{{$t("SEARCH_NEXO_MEMBER_HEADER_COMPONENT_SEARCH_BUTTON")}}</CButton>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import moment from 'moment';
import { Constants } from '@/constants';
import stringHelper from '@/utils/string-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';
export default {
  name: 'SearchNexoMemberHeader',
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterNexoMembers) || this.getDefaultValues(),
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    downloadCSV: {
      type: Function,
      default: null,
    },
    isAllowedDownloadCSV: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        email: '',
        last_name: '',
        first_name: '',
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();
      this.search();
    },
    async search() {
      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterNexoMembers, this.searchData);
      const searchData = {
        ...this.searchData,
      };
      this.onSearch(searchData);
    },
  }
}
</script>
<style lang="scss">
.search-nexo-member-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
        background: #3b4b7e;
      }

      &.btn-reset,
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>
