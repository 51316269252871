var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nexo-member-table-container"},[_c('CCard',{staticClass:"nexo-member-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage - 1) * _vm.limit))])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_c('StakingBadge',{attrs:{"type":"nexo-member-status","text":item.status}})],1)]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,_vm.dateFormat))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }