<template>
  <div class="nexo-member-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="12" sm="12">
            <h1>{{ $t('NEXO_MEMBER_LIST_PAGE_TITLE') }}</h1>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchNexoMemberHeader :onSearch="onSearch" :downloadCSV="downloadCSV" :isAllowedDownloadCSV="isAllowedDownloadCSV" />
        <NexoMemberListTable :limit="limit" :activePage="activePage" :items="items" :total="total" />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import NexoMemberListTable from '@/components/NexoMember/NexoMemberListTable';
import SearchNexoMemberHeader from '@/components/NexoMember/SearchNexoMemberHeader';
import { endpoints, env, Constants } from '@/constants';
import StakingPagination from '@/components/StakingPagination';
import responseHelper from '@/utils/response-helper';
import { mapState, mapActions, mapGetters } from 'vuex';
import { saveAs } from 'file-saver';

export default {
  name: 'NexoMemberList',
  components: {
    NexoMemberListTable,
    SearchNexoMemberHeader,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      isAllowedDownloadCSV: false,
      isLoading: true
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
      await this.getNexoMembers();
      this.checkPermissions();
      this.isLoading = false;
    },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'EXPORT_LIST_NEXO_MEMBER_CSV') {
            this.isAllowedDownloadCSV = true;
          }
        })
      }
    },
    async getNexoMembers(data) {
        data = data || {};
        this.filterData = {
          ...this.filterData,
          ...data,
        };
        const filterData = this.filterData;

        try {
          const params = {
            limit: this.limit,
            offset: this.offset,
            email: filterData.email,
            last_name: filterData.last_name,
            first_name: filterData.first_name
          };
          const result = await this.$http.get(endpoints.getNexoMembers, { params });

          this.items = result.data.items || [];
          this.total = result.data.total;
        } catch (err) {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: this.$t('NEXO_MEMBER_LIST_PAGE_TITLE_NOTIFY_ERROR'),
            text: responseHelper.getErrorMessage(err),
          });
        }
      },
      async onChangePage(page) {
        this.activePage = page;

        this.getNexoMembers();
      },
      async onChangePageSize(value) {
        this.limit = value;
        this.activePage = 1;

        this.getNexoMembers();
      },
      async onSearch(searchData) {
        const data = searchData;
        this.activePage = 1;
        this.getNexoMembers(data);
      },
      async downloadCSV() {
      const filterData = {
        ...this.filterData,
      };
      const params = {
          limit: this.limit,
            offset: this.offset,
            email: filterData.email,
            last_name: filterData.last_name,
            first_name: filterData.first_name
        };
      try {
        const result = await this.$http.get(endpoints.downloadNexoMemberCSV, { params });
        const data = result;
        const fileUrl = 'nexo-member.csv';
        const contentType = 'application/octet-stream';
        const file = new Blob([data], { type: contentType });
        saveAs(file, fileUrl);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('NEXO_MEMBER_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  }
};
</script>
<style lang="scss">
.nexo-member-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
